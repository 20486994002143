<template>
  <div>
    <b-overlay
      :show="overlay"
      rounded
      opacity="0.6"
      spinner-small
      spinner-variant="primary"
      class="d-inline-block"
    >
      <div>
        <b-form-checkbox
          v-model="cTracked"
          switch
          class="text-xs font-bold"
          @change="toggleTracker(cTracked)"
        >
          <div v-if="cTracked">ON!</div>
          <div v-else>OFF</div>
        </b-form-checkbox>

        <!-- <feather-icon class="ml-1 text-success" icon="MapPinIcon" /> -->
      </div>
    </b-overlay>
  </div>
</template>

<script>
//import { BButton, BOverlay } from "bootstrap-vue"
import { BFormCheckbox, BOverlay } from "bootstrap-vue"
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"

export default {
  name: "TrackedToggle",

  components: {
    BFormCheckbox,
    //BButton,
    BOverlay
  },

  props: {
    tableName: {
      type: String,
      default: "no name"
    },
    tracked: {
      type: Boolean,
      required: true
    },
    trackedcase: {
      type: String,
      required: true
    },
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      cTracked: false,
      overlay: false
    }
  },

  watch: {
    tracked(newValue) {
      this.cTracked = newValue
    }
  },

  created() {
    this.cTracked = this.tracked
  },

  methods: {
    async toggleTracker(nTracked) {
      const l1 = this
      l1.overlay = true

      if (!nTracked) {
        await this.$AuthAxios.instance
          .delete("/api-access/case-tracker/add-remove-tracker/", {
            params: {
              pk: l1.trackedcase
            }
          })
          .then(() => {
            l1.$store.dispatch("auth/incrementCasesTracked", -1)
            this.overlay = false
            this.$emit("removeNoteSubs")
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Case Successfully Removed from Tracking!`,
                icon: "EyeOffIcon",
                variant: "warning"
              }
            })

            this.$emit("updateTrackedToggle", {
              variant: "",
              tracked: false,
              index: l1.index,
              tableName: l1.tableName
            })
          })
          .catch((error) => {
            l1.overlay = false

            this.$emit("updateTrackedToggle", {
              variant: "success",
              tracked: true,
              index: l1.index,
              tableName: l1.tableName
            })
            console.log(error)
          })
      } else {
        await this.$AuthAxios.instance
          .post("/api-access/case-tracker/add-remove-tracker/", null, {
            params: {
              pk: l1.trackedcase
            }
          })
          .then(() => {
            l1.$store.dispatch("auth/incrementCasesTracked", 1)
            l1.overlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `You are now Tracking Case # ${l1.trackedcase}!`,
                icon: "EyeOnIcon",
                variant: "success"
              }
            })
            this.$emit("updateTrackedToggle", {
              variant: "success",
              tracked: true,
              index: l1.index,
              tableName: l1.tableName
            })
          })
          .catch((error) => {
            l1.overlay = false

            this.$emit("updateTrackedToggle", {
              variant: "",
              tracked: false,
              index: l1.index,
              tableName: l1.tableName
            })
            console.log(error)
          })
      }
    }
  }
}
</script>
