<template>
  <!-- TODO: REMOVE PLACEHOLDERS AND CALL ACTUAL STATS FROM BACKEND API -->
  <!-- TODO: REFORMAT GRAPH AND SET AS LINE GRAPH INSTEAD OF SPLIT BAR -->
  <section id="mail-stats">
    <b-row class="match-height">
      <b-col>
        <user-statistics
          :selected-data="selectedData"
          :data-selection="dataSelection"
          :is-loaded="isLoaded"
          @loaded="isLoaded += 1"
          @dataSelectUpdate="dataSelectEvent"
        />
      </b-col>
      <b-col xl="7" md="7">
        <unique-visitors
          :selected-data="selectedData"
          @loaded="isLoaded += 1"
        />
      </b-col>
    </b-row>

    <b-row class="match-height">
      <b-col lg="6">
        <contact-revisits
          :selected-data="selectedData"
          @loaded="isLoaded += 1"
        />
      </b-col>

      <b-col lg="6">
        <re-visits :selected-data="selectedData" @loaded="isLoaded += 1" />
      </b-col>
    </b-row>
    <b-row class="match-height">
      <b-col>
        <div id="contacts">
          <contacts-created
            :selected-data="selectedData"
            :is-loaded="isLoaded"
            @loaded="isLoaded += 1"
          />
        </div>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";
import UserStatistics from "../components/mail-stats/ConversionStats.vue";
import UniqueVisitors from "../components/mail-stats/UniqueVisitors.vue";
// import ContactsTable from "../components/mail-stats/ContactsTable.vue";
import ContactsCreated from "../components/mail-stats/ContactsCreated.vue";
import ReVisits from "../components/mail-stats/ReVisits.vue";
import ContactRevisits from "../components/mail-stats/ContactRevisits.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    // ContactsTable,
    BRow,
    BCol,
    UserStatistics,
    UniqueVisitors,
    ContactsCreated,
    ReVisits,
    ContactRevisits,
  },
  data() {
    return {
      isLoaded: 0,
      dataSelection: null,
      selectedData: {},
    };
  },
  computed: {
    ...mapGetters({
      accountType: "auth/role",
    }),
  },
  created() {
    this.request_access();
  },
  mounted() {
    this.goto("contacts");
  },
  methods: {
    goto(refName) {
      var element = document.getElementById(refName);

      element.scrollIntoView();
    },
    dataSelectEvent(item) {
      this.selectedData = item;
      console.log(item);
    },
    //'api-access/filing-view/attorney-and-case/',
    request_access() {
      //statistics/graphs/cases-filed/year-over-year/
      this.$AuthAxios.instance
        .get("/api-access/filing-view/request-access/", {})
        .then((res) => {
          this.selectedData = {
            type: res.data[0].type,
            value: res.data[0].value,
          };
          this.dataSelection = res.data;
          console.log(res);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
