<template>
  <b-card v-if="tableData" no-body class="card-company-table">
    <b-table
      :items="tableData"
      responsive
      sticky-header="420px"
      :fields="fields"
    >
      <!-- date -->
      <template #cell(history_date)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.history_date }}</span>
        </div>
      </template>

      <!-- entry -->
      <template #cell(history_value)="data">
        <div class="d-flex align-items-center">
          <span>{{ data.item.history_value }}</span>
        </div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import { BCard, BTable } from "bootstrap-vue"

export default {
  components: {
    BCard,
    BTable
    //BImg
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      fields: [
        { key: "history_value", label: "Historical Value" },
        { key: "history_date", label: "Date / Time" }
      ]
    }
  },
  methods: {
    formatDateTime(sDate, FormatType) {
      var lDate = new Date(sDate)

      var month = new Array(12)
      month[0] = "January"
      month[1] = "February"
      month[2] = "March"
      month[3] = "April"
      month[4] = "May"
      month[5] = "June"
      month[6] = "July"
      month[7] = "August"
      month[8] = "September"
      month[9] = "October"
      month[10] = "November"
      month[11] = "December"

      var weekday = new Array(7)
      weekday[0] = "Sunday"
      weekday[1] = "Monday"
      weekday[2] = "Tuesday"
      weekday[3] = "Wednesday"
      weekday[4] = "Thursday"
      weekday[5] = "Friday"
      weekday[6] = "Saturday"

      var hh = lDate.getHours() < 10 ? "0" + lDate.getHours() : lDate.getHours()
      var mi =
        lDate.getMinutes() < 10 ? "0" + lDate.getMinutes() : lDate.getMinutes()
      var ss =
        lDate.getSeconds() < 10 ? "0" + lDate.getSeconds() : lDate.getSeconds()

      var d = lDate.getDate()
      var dd = d < 10 ? "0" + d : d
      var yyyy = lDate.getFullYear()
      var mon = eval(lDate.getMonth() + 1)
      var mm = mon < 10 ? "0" + mon : mon
      var monthName = month[lDate.getMonth()]
      var weekdayName = weekday[lDate.getDay()]

      if (FormatType == 1) {
        return mm + "/" + dd + "/" + yyyy + " " + hh + ":" + mi
      } else if (FormatType == 2) {
        return weekdayName + ", " + monthName + " " + dd + ", " + yyyy
      } else if (FormatType == 3) {
        return mm + "/" + dd + "/" + yyyy
      } else if (FormatType == 4) {
        var dd1 = lDate.getDate()
        return dd1 + "-" + monthName + "-" + yyyy
      } else if (FormatType == 5) {
        return mm + "/" + dd + "/" + yyyy + " " + hh + ":" + mi + ":" + ss
      } else if (FormatType == 6) {
        return mon + "/" + d + "/" + yyyy + " " + hh + ":" + mi + ":" + ss
      } else if (FormatType == 7) {
        return (
          dd +
          "-" +
          monthName.substring(0, 3) +
          "-" +
          yyyy +
          " " +
          hh +
          ":" +
          mi +
          ":" +
          ss
        )
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
