
<template>
  <div class="flex flex-col w-full bg-white border rounded-lg shadow-sm pmr-1">
    <div class="flex flex-row justify-around pl-1 bg-gray-200 ">
      <label for="mailing-history">Mailing History</label>
      <label for="mailing-history">Type</label>
    </div>
    <vue-perfect-scrollbar
      v-once
      class="scroll-area"
      @wheel.prevent
      @touchmove.prevent
      @scroll.prevent
    >
      <div v-if="Object.keys(objItem).length > 0">
        <div
          v-for="item in objItem"
          :key="item.key"
          class="flex flex-row justify-around w-full pt-1 pl-1 text-sm"
        >
          <div>{{ item.date }}</div>
          <div>{{ item.type }}</div>
          <!-- <b-table
                responsive
                hide-footer
                sticky-header="113px"
                :items="items"
              >
                <template #head()="data">
                  <span>{{ data.label }}</span>
                </template>
              </b-table> -->
        </div>
      </div>
      <div
        v-else
        class="flex flex-row items-center justify-center w-full italic"
      >
        -- Never Mailed --
      </div>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar"
export default {
  components: { VuePerfectScrollbar },
  props: {
    objItem: {
      type: Object,
      required: true
    }
  },
  data: () => ({}),

  methods: {
    // eslint-disable-next-line no-unused-vars
    scrollHandle(evt) {
    
      document.documentElement.style.overflow = "hidden"

      return
    }
  }
}
</script>