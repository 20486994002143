<template>
  <b-overlay v-if="!selectedData" :show="true" rounded="lg" opacity="0.6">
    <b-card no-body class="pb-1 card-revenue-budget">
      <b-row class="mx-0">
        <b-col class="revenue-report-wrapper">
          <div
            class="mb-3 d-sm-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-50 mb-sm-0">Unique Visitors</h4>
            <div class="d-flex align-items-center">
              <div
                v-for="(value, key) in revenue.revenueReport.series"
                :key="key"
                class="ml-10 d-flex align-items-center"
              >
                <span
                  :class="`cursor-pointer bullet ${
                    key === 0
                      ? 'bullet-primary'
                      : key === 1
                      ? 'bullet-secondary'
                      : 'bullet-warning'
                  } svg-font-small-3 mr-50`"
                />
                <span>{{ value.name }}</span>
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            height="295"
            :options="revenueReport.chartOptions"
            :series="revenue.revenueReport.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
  <b-overlay v-else :show="isBusy" rounded="lg" opacity="0.6">
    <b-card no-body class="card-revenue-budget">
      <b-row class="mx-0">
        <b-col class="revenue-report-wrapper">
          <div
            class="mb-3 d-sm-flex justify-content-between align-items-center"
          >
            <h4 class="card-title mb-50 mb-sm-0">Unique Visitors</h4>
            <div class="d-flex align-items-center">
              <div
                v-for="(value, key) in revenue.revenueReport.series"
                :key="key"
                class="ml-10 d-flex align-items-center"
              >
                <date-dropdown @dateSelected="dateSelectedEvent" />
              </div>
            </div>
          </div>

          <!-- chart -->
          <vue-apex-charts
            id="revenue-report-chart"
            :key="chartKey"
            height="295"
            :options="revenueReport.chartOptions"
            :series="revenue.revenueReport.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </b-overlay>
</template>

<script>
//<b-overlay :show="isBusy" rounded="lg" opacity="0.6">
import {
  BCard,
  BRow,
  BCol,
  BOverlay,
  //BDropdown,
  //BDropdownItem,
  //BButton
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";
import DateDropdown from "./DateDropdown.vue";

export default {
  components: {
    BOverlay,
    VueApexCharts,
    // BDropdown,
    DateDropdown,
    // BDropdownItem,
    BCard,
    // BButton,
    BRow,
    BCol,
  },
  directives: {
    Ripple,
  },
  props: {
    selectedData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chartKey: 0,
      dateRange: "Last 14 days",
      isBusy: true,
      revenue: {
        revenueReport: {
          series: [
            {
              name: "Daily",
              data: [
                0, 5, 3, 0, 17, 6, 4, 0, 0, 2, 7, 3, 0, 5, 3, 0, 17, 6, 4, 0, 0,
                2, 7, 3,
              ],
            },
          ],
        },
      },
      loaded: false,

      revenueReport: {
        chartOptions: {
          chart: {
            stacked: false,
            type: "bar",
            toolbar: { show: false },
          },
          grid: {
            padding: {
              top: -20,
              bottom: -10,
            },
            yaxis: {
              lines: { show: true },
            },
          },
          xaxis: {
            categories: [
              "Feb 19",
              "Feb 20",
              "Feb 21",
              "Feb 22",
              "Feb 23",
              "Feb 24",
              "Feb 25",
              "Feb 26",
              "Feb 27",
              "Feb 28",
              "Mar 1",
              "Mar 2",
              "Feb 19",
              "Feb 20",
              "Feb 21",
              "Feb 22",
              "Feb 23",
              "Feb 24",
              "Feb 25",
              "Feb 26",
              "Feb 27",
              "Feb 28",
              "Mar 1",
              "Mar 2",
            ],
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
            axisTicks: {
              show: false,
            },
            axisBorder: {
              show: false,
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },

          colors: [$themeColors.primary],
          plotOptions: {
            bar: {
              columnWidth: "17%",
              endingShape: "rounded",
            },
            distributed: true,
          },
          yaxis: {
            labels: {
              style: {
                colors: "#6E6B7B",
                fontSize: "0.86rem",
                fontFamily: "Montserrat",
              },
            },
          },
        },
      },
    };
  },
  watch: {
    selectedData(newValue) {
      this.get_graph_data(newValue, this.dateRange);
    },
  },

  methods: {
    dateSelectedEvent(date) {
      this.dateRange = date;
      this.get_graph_data(this.selectedData, date);
    },
    get_graph_data(data, dateRange) {
      this.isBusy = true;
      this.$AuthAxios.instance
        .get("/api-access/filing-view/unique-impressions/", {
          params: {
            user: data,
            dateRange: dateRange,
            graphType: "unique_impressions",
          },
        })
        .then((res) => {
          this.revenueReport.chartOptions.xaxis.categories = res.data.dates;
          this.revenue.revenueReport.series = [
            {
              name: "Daily",
              data: res.data.values,
            },
          ];

          this.chartKey += 1;
          this.isBusy = false;
        })
        .catch(() => (this.isBusy = false));
    },
  },
};
</script>
