<template>
  <b-dropdown
    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
    split
    right
    block
    size="sm"
    :text="selected.name"
    split-variant="outline-primary"
    variant="outline-primary"
  >
    <b-dropdown-item
      v-for="item in dataSelection"
      :key="item"
      @click="setSelection(item)"
      >{{ item.name }}</b-dropdown-item
    >
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    Ripple,
  },

  components: { BDropdown, BDropdownItem },
  props: {
    dataSelection: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selected: this.dataSelection[0],
      value: this.dataSelection[0].value,
      type: this.dataSelection[0].type,
    };
  },

  methods: {
    setSelection(item) {
      this.selected = item;
      this.value = item.value;
      this.type = item.type;
      this.$emit("dataSelectUpdate", { value: this.value, type: this.type });
    },
  },
};
</script>
