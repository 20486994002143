<template>
  <b-list-group
    :class="{
      'mx-8': trackedTable ? false : true,
      'mb-3': trackedTable ? false : true,
      'shadow-sm': true,
    }"
  >
    <b-list-group-item active class="shadow-md">
      <div class="pr-20 mt-1 mb-2 d-flex w-100 justify-content-end">
        <small class="text-secondary"
          >last updated {{ updateCalculation(casedata.updated_date) }} days
          ago</small
        >
      </div>
      <div class="px-10 flex-column align-items-start">
        <b-card-text>
          <div class="w-full lg:flex lg:flex-row">
            <div class="w-full my-1 mr-5 font-bold lg:w-1/4">
              <div class="mb-1 text-2xl text-center">
                <span class="lg:ml-2">{{ casedata.case_title }}</span>
              </div>
              <div class="mb-2 text-xl text-center">
                <span class="lg:ml-2">{{ casedata.case_number }}</span>
              </div>
              <div class="text-lg text-center">
                <span class="text-gray-500 lg:ml-2"
                  >Judge {{ casedata.judge }}</span
                >
              </div>
              <div class="text-lg text-center">
                <span class="text-gray-500 lg:ml-2"
                  >{{ capLetters(casedata.county) }} County</span
                >
              </div>
              <div
                v-if="tracked"
                class="
                  flex flex-col
                  items-center
                  p-2
                  mt-10
                  bg-white
                  rounded-lg
                  shadow-md
                  border-success
                  text-success
                "
              >
                <div class="text-lg">
                  <span class="font-bold lg:ml-2">CASE IS TRACKED</span>
                </div>
                <tracked-toggle
                  :ref="casedata.table_name"
                  :index="casedata.index"
                  :trackedcase="casedata.case_number"
                  :tracked="tracked"
                  :table-name="casedata.tableName"
                  class="mt-2 item-center"
                  v-on="$listeners"
                  @removeNoteSubs="removeNoteSubs"
                />
                <!-- <b-button
                  variant="success"
                  class="mt-2 item-center"
                  @click.stop.prevent="toggleTracker(true)"
                >
                  REMOVE
                </b-button> -->
              </div>
              <div
                v-else
                class="
                  flex flex-col
                  items-center
                  p-2
                  mt-10
                  bg-white
                  rounded-lg
                  shadow-md
                  border-warning
                  text-warning
                "
              >
                <div class="text-lg">
                  <span class="font-bold lg:ml-2">CASE NOT TRACKED</span>
                </div>
                <tracked-toggle
                  :index="casedata.index"
                  :trackedcase="casedata.case_number"
                  :tracked="casedata.is_tracked"
                  :table-name="casedata.table_name"
                  class="mt-2 item-center"
                  @updateTrackedToggle="$emit('updateTrackedToggle', $event)"
                  @removeNoteSubs="removeNoteSubs"
                />
                <!-- <b-button
                  variant="success"
                  class="mt-2 item-center"
                  @click.stop.prevent="(false)"
                >
                  ADD
                </b-button> -->
              </div>
            </div>
            <div
              class="
                flex flex-col
                w-3/4
                p-2
                bg-white
                rounded-lg
                shadow-md
                text-black2
              "
            >
              <div class="lg:flex lg:flex-row">
                <div class="ml-10 w-1/8">
                  <b-table-simple>
                    <b-tbody></b-tbody>
                  </b-table-simple>
                </div>
                <div class="w-1/2 ml-2 text-black2">
                  <b-table-simple>
                    <b-thead>
                      <b-tr class="font-bold">
                        <span class="mr-1">Notifications / History</span>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <notification-toggle
                        :set="
                          (casedata['hearing_date'] = formatDate(
                            casedata['hearing_date'],
                            'hearing_date'
                          ))
                        "
                        :casedata="casedata"
                        field="hearing_date"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="true"
                        title="Hearing Date"
                        @updateTotalCaseView="updateTotalCaseView"
                        @showHistory="showHistory"
                        @toggleTracker="toggleTracker"
                      />
                      <notification-toggle
                        :casedata="casedata"
                        field="amount"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="true"
                        title="Amount"
                        @updateTotalCaseView="updateTotalCaseView"
                        @showHistory="showHistory"
                        @toggleTracker="toggleTracker"
                      />
                      <notification-toggle
                        :casedata="casedata"
                        field="case_status"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="true"
                        title="Status"
                        @updateTotalCaseView="updateTotalCaseView"
                        @showHistory="showHistory"
                        @toggleTracker="toggleTracker"
                      />

                      <notification-toggle
                        :set="
                          (casedata['case_status_date'] = formatDate(
                            casedata['case_status_date']
                          ))
                        "
                        :casedata="casedata"
                        field="case_status_date"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="false"
                        title="Status Date"
                        @updateTotalCaseView="updateTotalCaseView"
                      />

                      <notification-toggle
                        :set="
                          (casedata['file_date'] = formatDate(
                            casedata['file_date']
                          ))
                        "
                        :casedata="casedata"
                        field="file_date"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="false"
                        title="File Date"
                        @toggleTracker="toggleTracker"
                        @updateTotalCaseView="updateTotalCaseView"
                      />
                    </b-tbody>
                  </b-table-simple>
                </div>
                <div class="w-1/2 ml-2 mr-3 lg:ml-2">
                  <b-table-simple class="">
                    <b-thead head-variant="dark">
                      <b-tr>
                        <small class="text-secondary">Subscribe </small>
                      </b-tr>
                    </b-thead>
                    <b-tbody>
                      <notification-toggle
                        :casedata="casedata"
                        field="case_type"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="false"
                        title="Case Type"
                        @updateTotalCaseView="updateTotalCaseView"
                        @toggleTracker="toggleTracker"
                      />

                      <notification-toggle
                        :set="
                          (casedata['updated_date'] = formatDate(
                            casedata['updated_date']
                          ))
                        "
                        :casedata="casedata"
                        field="updated_date"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="false"
                        title="Updated Date"
                        @toggleTracker="toggleTracker"
                        @updateTotalCaseView="updateTotalCaseView"
                      />
                      <!-- TODO: UPDATE DISMISSED TO SHOW 'YES' OR 'NO' -->
                      <notification-toggle
                        :casedata="casedata"
                        field="is_case_dismissed"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="false"
                        title="Dismissed"
                        @updateTotalCaseView="updateTotalCaseView"
                        @toggleTracker="toggleTracker"
                      />
                      <notification-toggle
                        :casedata="casedata"
                        field="case_dismiss_date"
                        model="Case"
                        :pk="casedata.case_number"
                        :active="false"
                        title="Dismissed Date"
                        @updateTotalCaseView="updateTotalCaseView"
                        @toggleTracker="toggleTracker"
                      />
                    </b-tbody>
                  </b-table-simple>
                  <div class="float-right mr-10">
                    <!--  <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    v-b-toggle.collapse-1
                    variant="secondary"
                    class="mr-1"
                  >
                    View All Case Data
                  </b-button> -->
                  </div>
                </div>
              </div>

              <history-accordion
                :data-pack="dataPack"
                bg-color="bg-white"
                component-padding="px-3"
              />
            </div>
          </div>
        </b-card-text>
      </div>
      <div class="px-20 mt-2 mb-1 d-flex w-100 justify-content-between">
        <small class="text-secondary"></small>
        <small class="text-secondary"></small>
      </div>
    </b-list-group-item>

    <div>
      <!-- toggle button -->

      <!--  <b-collapse id="collapse-1">
        <b-card class="mb-0 border">
          <b-card-text class="card-text">
            <div class="flex flex-col">
              <div class="flex flex-row justify-start">
                <div class="text-xl font-bold underline">
                  {{ formatStatus(history) }}
                </div>
              </div>
              <div class="flex flex-row justify-end">
                <div>
                  <b-button
                    v-b-toggle.collapse-1
                    variant="secondary"
                    class="mr-1"
                    @click.stop="resetHistory"
                  >
                    Close
                  </b-button>
                </div>
              </div>
              <div class="self-center w-3/4">
                <history-table :table-data="history[Object.keys(history)[0]]" />
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-collapse> -->
    </div>

    <b-list-group-item class="p-0 bg-white">
      <app-collapse accordion type="border">
        <app-collapse-item
          v-for="(item, id) in casedata.case_of_party"
          :key="item.unique_id"
          :item="item"
          :obj="item"
          :uid="item.defendant"
          :index="id"
          :date="item.updated_date"
          :defendant="item.defendant"
          :totalid="item.length"
          :do-not-mail="item.do_not_mail"
        >
          <b-card-text>
            <defendant-details
              :case-number="casedata.case_number"
              :item="item"
              :index="casedata.index"
              :c-tracked="casedata.is_tracked"
            />
          </b-card-text>
        </app-collapse-item>
      </app-collapse>
    </b-list-group-item>
  </b-list-group>
</template>

<script>
// import DetailsAccordion from "./DetailsAccordion"
import {
  // BButton,
  // BCollapse,
  // BCard,
  //BFormCheckbox,
  BListGroup,
  BListGroupItem,
  BCardText,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import TrackedToggle from "./TrackedToggle";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import NotificationToggle from "./NotificationToggle.vue";
import AppCollapse from "./DefendantCollapse/AppCollapse";
import AppCollapseItem from "./DefendantCollapse/AppCollapseItem";
import DefendantDetails from "./DefendantDetails";
import HistoryAccordion from "./HistoryAccordion";

export default {
  name: "TotalCaseView",
  components: {
    // BButton,
    // BCard,
    // BCollapse,
    // HistoryTable,
    // DetailsAccordion,
    //BFormCheckbox,
    HistoryAccordion,
    TrackedToggle,
    AppCollapse,
    AppCollapseItem,
    DefendantDetails,
    NotificationToggle,
    BListGroup,
    BListGroupItem,
    BCardText,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
  },
  directives: {
    "b-toggle": VBToggle,
    Ripple,
  },

  props: {
    trackedTable: {
      type: Boolean,
      default: false,
    },
    casedata: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dataPack: { active: false, field: false, model: false, pk: false },

      history: false,
      tracked: false,
      status_notify: false,
      filed_date_notify: false,

      index: 0,
      overlay: false,
    };
  },
  watch: {
    casedata: {
      handler(newValue) {
        this.tracked = newValue.is_tracked;
      },
      deep: true,
    },
  },
  methods: {
    toggleHistoryAccordion(dataPack) {
      this.dataPack = dataPack;
    },
    removeNoteSubs() {
      var l1 = this;
      Object.entries(l1.casedata.note_subs).forEach(([key]) => {
        l1.casedata.note_subs[key] = false;
      });

      Object.entries(l1.casedata.case_of_party).forEach(([key]) => {
        Object.entries(l1.casedata.case_of_party[key].note_subs).forEach(
          ([key1]) => {
            l1.casedata.case_of_party[key].note_subs[key1] = false;
          }
        );
      });
    },
    // resetHistory() {
    //   this.history = false
    // },
    // formatStatus(status) {
    //   var statusValue = ""
    //   var capStatus = "History for"
    //   if (typeof status === "object") {
    //     statusValue = Object.keys(status)[0]
    //     const splitData = statusValue.split("_")
    //
    //     splitData.forEach((word) => {
    //       var splitWord = word.charAt(0).toUpperCase() + word.slice(1)
    //
    //       capStatus = `${capStatus} ${splitWord}`
    //     })
    //
    //     return capStatus
    //   }
    // },
    showHistory() {},

    // showHistory(data) {
    //   if (!this.history) {
    //     this.history = data
    //
    //     return this.$root.$emit("bv::toggle::collapse", "collapse-1")
    //   }
    //
    //   this.history = data
    // },
    updateTotalCaseView(variable) {
      this.$emit("updateSearchFilings", variable);
    },
    capLetters(mySentence) {
      const words = mySentence.split(" ");

      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].substr(1);
      }

      return words.join(" ");
    },
    formatDate(date) {
      let fDate = new Date(date).toLocaleDateString("en-US", {
        timeZone: "UTC",
      });
      if (fDate === "Invalid Date" || !date) {
        return "not set";
      }

      return fDate;
    },

    async toggleTracker(nTracked) {
      const l1 = this;
      l1.overlay = true;
      l1.trackedcase = l1.casedata.case_number;

      if (!nTracked) {
        await this.$AuthAxios.instance
          .delete("/api-access/case-tracker/add-remove-tracker/", {
            params: {
              pk: l1.trackedcase,
            },
          })
          .then(() => {
            this.overlay = false;
            this.$emit("removeNoteSubs");
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Case Successfully Removed from Tracking!`,
                icon: "EyeOffIcon",
                variant: "warning",
              },
            });

            this.$emit("updateTrackedToggle", {
              variant: "",
              tracked: false,
              index: l1.index,
              tableName: l1.casedata.tableName,
            });
          })
          .catch((error) => {
            l1.overlay = false;

            this.$emit("updateTrackedToggle", {
              variant: "success",
              tracked: true,
              index: l1.index,
              tableName: l1.casedata.tableName,
            });
            console.log(error);
          });
      } else {
        await this.$AuthAxios.instance
          .post("/api-access/case-tracker/add-remove-tracker/", null, {
            params: {
              pk: l1.trackedcase,
            },
          })
          .then(() => {
            l1.$store.dispatch("auth/incrementCasesTracked");
            l1.overlay = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `You are now Tracking Case # ${l1.trackedcase}!`,
                icon: "EyeOnIcon",
                variant: "success",
              },
            });
            this.$emit("updateTrackedToggle", {
              variant: "success",
              tracked: true,
              index: l1.index,
              tableName: l1.casedata.tableName,
            });
          })
          .catch((error) => {
            l1.overlay = false;

            this.$emit("updateTrackedToggle", {
              variant: "",
              tracked: false,
              index: l1.index,
              tableName: l1.casedata.tableName,
            });
            console.log(error);
          });
      }
    },

    updateCalculation(d1) {
      var t1 = new Date(d1).getTime();
      var t2 = new Date().getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },

    test() {},
  },
};
</script>