<template>
  <div class="p-1 rounded-lg">
    <div class="flex-column align-items-start">
      <b-card-text>
        <div class="w-full lg:flex lg:flex-row">
          <div class="w-full mr-2 lg:w-1/4 d-flex">
            <div class="w-full">
              <b-col
                v-if="$can('post', 'siteOwner')"
                cols="12 p-1 border rounded-lg shadow-md bg-gray-100 m-1 italic"
              >
                <div class="text-sm underline">** site admin only</div>
                <div class="mt-2">
                  <!-- TODO: FINISH ADDING ABILITY TO UPDATE DEFENDANT CONTACT INFORMATION 
                              IN DJANGO. ALSO, SET FORM RESTRICTIONS AND VALIDATION.
                  -->
                  <b-form-group label="Street Address" label-for="vi-street">
                    <b-input-group class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <defendant-notification-toggle
                          :form-input="true"
                          :casedata="item"
                          field="total_address"
                          :active="true"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        :id="`${item.unique_id}-vi-street`"
                        v-model="item.street"
                        class="pl-1"
                        :placeholder="item.street"
                      />
                    </b-input-group>
                  </b-form-group>
                  <div class="flex flex-row">
                    <div class="flex-1">
                      <b-form-group label="City" label-for="vi-city">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="`${item.unique_id}-vi-city`"
                            v-model="item.city"
                            :placeholder="item.city"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="flex-1">
                      <b-form-group label="State" label-for="vi-state">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="`${item.unique_id}-vi-state`"
                            v-model="item.state"
                            :placeholder="item.state"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="flex-1">
                      <b-form-group label="Zip" label-for="vi-zip">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="`${item.unique_id}-vi-zip`"
                            v-model="item.zip_code"
                            :placeholder="item.zip_code"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="my-2">
                  <div :set="(fName = 'phone')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-phone`"
                          v-model="item[fName]"
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div :set="(fName = 'email')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-email`"
                          v-model="item[fName]"
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div :set="(fName = 'employer_info')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-employer`"
                          v-model="item[fName]"
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div :set="(fName = 'additional_info')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-additional`"
                          v-model="item[fName]"
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div>
                    <b-button @click="updateDefendantInfo">Update</b-button>
                  </div>
                </div>
              </b-col>
              <b-col v-else cols="12 p-1 border rounded-lg shadow-md ">
                <div class="mt-2">
                  <!-- TODO: FINISH ADDING ABILITY TO UPDATE DEFENDANT CONTACT INFORMATION 
                              IN DJANGO. ALSO, SET FORM RESTRICTIONS AND VALIDATION.
                  -->
                  <b-form-group label="Street Address" label-for="vi-street">
                    <b-input-group disabled class="input-group-merge">
                      <b-input-group-prepend is-text>
                        <defendant-notification-toggle
                          :form-input="true"
                          :casedata="item"
                          field="total_address"
                          :active="true"
                        />
                      </b-input-group-prepend>
                      <b-form-input
                        :id="`${item.unique_id}-vi-street`"
                        v-model="item.street"
                        disabled
                        class="pl-1"
                        :placeholder="item.street"
                      />
                    </b-input-group>
                  </b-form-group>
                  <div class="flex flex-row">
                    <div class="flex-1">
                      <b-form-group label="City" label-for="vi-city">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="`${item.unique_id}-vi-city`"
                            v-model="item.city"
                            disabled
                            :placeholder="item.city"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="flex-1">
                      <b-form-group label="State" label-for="vi-state">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="`${item.unique_id}-vi-state`"
                            v-model="item.state"
                            disabled
                            :placeholder="item.state"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                    <div class="flex-1">
                      <b-form-group label="Zip" label-for="vi-zip">
                        <b-input-group class="input-group-merge">
                          <b-form-input
                            :id="`${item.unique_id}-vi-zip`"
                            v-model="item.zip_code"
                            disabled
                            :placeholder="item.zip_code"
                          />
                        </b-input-group>
                      </b-form-group>
                    </div>
                  </div>
                </div>

                <div class="my-2">
                  <div :set="(fName = 'phone')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-phone`"
                          v-model="item[fName]"
                          disabled
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div :set="(fName = 'email')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-email`"
                          v-model="item[fName]"
                          disabled
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div :set="(fName = 'employer_info')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-employer`"
                          v-model="item[fName]"
                          disabled
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                  <div :set="(fName = 'additional_info')">
                    <b-form-group
                      :label="fName"
                      :label-for="`${'vi-' + fName}`"
                    >
                      <b-input-group class="input-group-merge">
                        <b-input-group-prepend is-text>
                          <defendant-notification-toggle
                            :form-input="true"
                            :casedata="item"
                            :field="fName"
                            :active="true"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          :id="`${item.unique_id}-vi-additional`"
                          v-model="item[fName]"
                          disabled
                          class="pl-1"
                          :placeholder="item[fName]"
                        />
                      </b-input-group>
                    </b-form-group>
                  </div>
                </div>
              </b-col>
            </div>
          </div>

          <div class="w-3/4">
            <div class="flex flex-col w-full">
              <div
                class="
                  flex flex-col
                  justify-around
                  w-full
                  pt-1
                  bg-gray-200
                  border
                  rounded-lg
                  shadow-sm
                "
              >
                <div class="flex flex-row justify-around w-full">
                  <defendant-notification-toggle
                    :casedata="item"
                    field="serve_status"
                    title="Served Status"
                    dateattach="serve_status_date"
                  />

                  <defendant-notification-toggle
                    :casedata="item"
                    field="is_bankruptcy_filed"
                    :bool="true"
                    dateattach="bankruptcy_filed_date"
                    title="Bankruptcy?"
                  />
                  <defendant-notification-toggle
                    :casedata="item"
                    field="is_garnishment"
                    :bool="true"
                    dateattach="garnishment_date"
                    title="Garnishment?"
                  />

                  <defendant-notification-toggle
                    :casedata="item"
                    field="is_agreement"
                    :bool="true"
                    dateattach="agreement_date"
                    title="Agreement?"
                  />
                </div>
                <div>
                  <history-accordion :data-pack="dataPack" />
                </div>
              </div>
              <b-row class="pt-1 match-height">
                <!-- Company Table Card -->
                <b-col lg="3">
                  <div class="flex flex-col pt-1 pl-2">
                    <defendant-notification-toggle
                      :casedata="item"
                      field="garnishment_status"
                      title="Garnishment Status"
                    />

                    <defendant-notification-toggle
                      :casedata="item"
                      field="last_pay_date"
                      :date="true"
                      title="Last Pay Date"
                      class="w-1/4"
                    />
                    <defendant-notification-toggle
                      :casedata="item"
                      field="garnishment_answer"
                      dateattach="garnishment_answer_date"
                      title="Garnishment Answer"
                    />

                    <defendant-notification-toggle
                      :casedata="item"
                      field="reference"
                      title="Reference"
                    />
                  </div>
                </b-col>
                <b-col lg="9">
                  <defendant-rule-dockets :table-data="item.dockets" />
                </b-col>
                <!--/ Company Table Card -->

                <!--/ Transaction Card -->
              </b-row>
            </div>
          </div>
        </div>
      </b-card-text>
    </div>
  </div>
</template>

<script>
//import { BButton, BOverlay } from "bootstrap-vue"

import {
  BButton,
  BCol,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,

  // BListGroup,
  // BListGroupItem,
  BRow,
  BCardText,
  //BTableSimple,
  //BThead,
  //BTbody,
  //BTr
} from "bootstrap-vue";
import DefendantNotificationToggle from "./DefendantNotificationToggle.vue";
//import TextInput from "./TextInput"
import DefendantRuleDockets from "./DefendantRuleDockets";
import HistoryAccordion from "./HistoryAccordion";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
// import CaseStatusDropdown from "./CaseStatusDropdown"
//import DefendantNotification2 from "./DefendantNotification2.vue"

export default {
  name: "DefendantDetails",
  components: {
    //TextInput,
    DefendantNotificationToggle,
    DefendantRuleDockets,
    HistoryAccordion,
    //CaseStatusDropdown,
    //DefendantNotification2,
    BButton,

    BCol,
    BRow,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    //BListGroup,
    //BListGroupItem,
    BCardText,
    ///BTableSimple,
    ///BThead,
    ///BTbody,
    //BTr
  },

  props: {
    index: {
      type: Number,
      default: 0,
    },
    item: {
      type: Object,
      required: true,
    },
    cTracked: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      dataPack: { active: false, field: false, model: false, pk: false },
      fStreet: "",
      fCity: "",
      fState: "",
      fZip: "",
      fPhone: "",
      fEmail: "",
      fEmployer: "",
      fAdditional: "",

      overlay: false,
    };
  },

  methods: {
    updateDefendantInfo() {
      this.$AuthAxios.instance
        .put("/api-access/search/defendant-info/", {
          data: {
            unique_id: this.item.unique_id,
            street: this.item.street,
            city: this.item.city,
            state: this.item.state,
            zip_code: this.item.zip_code,
            phone: this.item.phone,
            email: this.item.email,
            employer_info: this.item.employer_info,
            additional_info: this.item.additional_info,
          },
        }) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.item.unique_id} has been successfully Updated!`,
              icon: "UserIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `OOPS, Something went wrong..... ${error}`,
              icon: "CircleXIcon",
              variant: "danger",
            },
          });
        });
    },
    toggleHistoryAccordion(dataPack) {
      this.dataPack = dataPack;
    },

    updateCalculation(d1) {
      var t1 = new Date(d1).getTime();
      var t2 = new Date().getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },
  },
};
</script>
