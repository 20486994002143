<template>
  <div>
    <div v-if="formInput">
      <div>
        <div>
          <b-overlay
            :show="overlay"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            class="flex flex-row font-bold"
          >
            <div
              v-if="casedata.note_subs[field]"
              v-b-tooltip.hover.v-primary
              title="toggle notification for this field"
              variant="outline-primary"
              class="mr-1 cursor-pointer btn-icon"
              @click.stop="
                toggleNotify(
                  field,
                  casedata.unique_id,
                  'CaseParty',

                  casedata.note_subs[field]
                )
              "
            >
              <feather-icon class="text-success" icon="BellIcon" />
            </div>

            <div
              v-else
              v-b-tooltip.hover.v-primary
              title="toggle notification for this field"
              variant="outline-primary"
              class="mr-1 cursor-pointer btn-icon"
              @click.stop="
                toggleNotify(
                  field,
                  casedata.unique_id,
                  'CaseParty',

                  casedata.note_subs[field]
                )
              "
            >
              <feather-icon class="text-warning" icon="BellOffIcon" />
            </div>
            <div
              v-b-tooltip.hover.v-primary
              title="toggle notification for this field"
              variant="outline-primary"
              class="cursor-pointer btn-icon"
              @click.stop="
                $parent.toggleHistoryAccordion({
                  active: true,
                  field: field,
                  model: 'CaseParty',
                  pk: casedata.unique_id
                })
              "
            >
              <history-icon class="text-info" />
            </div>
            <div>{{ title }}</div>
          </b-overlay>
        </div>
      </div>
    </div>
    <div v-else class="mb-2">
      <div class="w-64 h-24">
        <div class="font-bold border-bottom">
          <b-overlay
            :show="overlay"
            rounded
            opacity="0.6"
            spinner-small
            spinner-variant="primary"
            ><div class="flex flex-row">
              <div
                v-if="casedata.note_subs[field]"
                v-b-tooltip.hover.v-primary
                title="toggle notification for this field"
                variant="outline-primary"
                class="mr-1 cursor-pointer btn-icon"
                @click.stop="
                  toggleNotify(
                    field,
                    casedata.unique_id,
                    'CaseParty',

                    casedata.note_subs[field]
                  )
                "
              >
                <feather-icon class="text-success" icon="BellIcon" />
              </div>

              <div
                v-else
                v-b-tooltip.hover.v-primary
                title="toggle notification for this field"
                variant="outline-primary"
                class="mr-1 cursor-pointer btn-icon"
                @click.stop="
                  toggleNotify(
                    field,
                    casedata.unique_id,
                    'CaseParty',

                    casedata.note_subs[field]
                  )
                "
              >
                <feather-icon class="text-warning" icon="BellOffIcon" />
              </div>
              <div
                v-b-tooltip.hover.v-primary
                title="view history for this field"
                variant="outline-primary"
                class="mr-1 cursor-pointer btn-icon"
                @click.stop="
                  $parent.toggleHistoryAccordion({
                    active: true,
                    field: field,
                    model: 'CaseParty',
                    pk: casedata.unique_id
                  })
                "
              >
                <history-icon class="text-info" />
              </div>
              <div class="float-right ">{{ title }}</div>
            </div>
          </b-overlay>
        </div>

        <div v-if="!formInput" class="flex flex-col h-full">
          <div
            v-if="caselink"
            class="w-full mt-1 text-center underline text-primary"
            @click.stop.prevent="openCourtLink(casedata[field])"
          >
            Visit Case
          </div>
          <div v-else-if="dateattach" class="h-full">
            <div
              class="flex flex-col content-around w-full h-full justify-evenly"
            >
              <div class="w-full text-center">
                {{ typeCheck(casedata[field]) }}
              </div>
              <span class="w-full text-sm italic text-right text-gray-500"
                >{{
                  casedata[dateattach] ? formatDate(casedata[dateattach]) : ""
                }}
              </span>
            </div>
          </div>
          <div v-else class="h-full text-center">
            <div
              class="flex flex-col content-around w-full h-full justify-evenly"
            >
              <div class="w-full text-center">
                {{ typeCheck(casedata[field]) }}
              </div>
            </div>
          </div>
          <!--  <span
            v-if="url"
            class="w-full text-center cursor-pointer"
            @click.stop.prevent="openCourtLink(casedata[field])"
          >
            {{ casedata[field] }}
          </span>
          <span else-if class="w-full text-center">
            {{ typeCheck(casedata[field]) }}
          </span>
          <div
            v-if="dateattach"
            class="w-full mt-1 text-sm italic text-right text-gray-500"
          >
            {{ formatDate(casedata[date]) }} 01/22/2021
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip, BOverlay } from "bootstrap-vue"
import Ripple from "vue-ripple-directive"

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue"
import HistoryIcon from "./HistoryIcon"

export default {
  name: "DefendantNotificationToggle",
  components: {
    HistoryIcon,
    //BButton,
    BOverlay
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },

  props: {
    casedata: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ""
    },
    formInput: {
      type: Boolean,
      required: false
    },
    bool: {
      type: Boolean,
      default: false
    },
    date: {
      type: Boolean,
      default: false
    },
    dateattach: {
      type: String,
      default: ""
    },
    caselink: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      overlay: false
    }
  },

  methods: {
    openCourtLink(url) {
      //let route = this.$router.resolve({ path: url })

      // let route = this.$router.resolve('/link/to/page'); // This also works.
      window.open(url, "_blank")
    },
    formatDate(date) {
      if (date) {
        return new Date(date).toLocaleDateString("en-US")
      } else {
        return false
      }
    },
    typeCheck(data) {
      if (this.bool) {
        return data ? "Yes" : "No"
      } else if (this.date) {
        if (this.formatDate(data)) {
          return this.formatDate(data)
        } else {
          return "None"
        }
      } else {
        if (data) {
          return data
        } else {
          return "None"
        }
      }
    },

    async toggleNotify(field, pk, model, toggleValue) {
      const l1 = this
      l1.overlay = true

      if (toggleValue) {
        await this.$AuthAxios.instance
          .delete("/api-access/notifications/add-remove-notifications/", {
            params: {
              field: field,
              model: model,
              pk: pk
            }
          }) // eslint-disable-next-line no-unused-vars
          .then((res) => {
            l1.overlay = false

            this.$toast({
              component: ToastificationContent,
              props: {
                title: `The '${field}' notification has been removed!`,
                icon: "EyeOffIcon",
                variant: "warning"
              }
            })

            l1.casedata.note_subs[field] = false

            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index
            })
          })
          .catch((error) => {
            l1.overlay = false
            l1.casedata.note_subs[field] = true

            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index
            })
            console.log(error)
          })
      } else {
        await this.$AuthAxios.instance
          .post("/api-access/notifications/add-remove-notifications/", null, {
            params: {
              field: field,
              model: model,
              pk: pk
            }
          }) // eslint-disable-next-line no-unused-vars
          .then((res) => {
            if (!l1.$getComponent("DefendantDetails").cTracked) {
              l1.$getComponent("TotalCaseView").toggleTracker(false)
            }

            l1.overlay = false
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `The '${field}' notification has been successfully set!`,
                icon: "EyeOnIcon",
                variant: "success"
              }
            })

            l1.casedata.note_subs[field] = true
            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index
            })
          })
          .catch((error) => {
            l1.overlay = false
            l1.casedata.note_subs[field] = false
            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index
            })
            console.log(error)
          })
      }
    }
  }
}
</script>
<!--
   // <div v-else class="mb-1 mr-1">
   //    <div>
   //      <div>
   //        <div class="font-bold ">
   //          <b-overlay
   //            :show="overlay"
   //            rounded
   //            opacity="0.6"
   //            spinner-small
   //            spinner-variant="primary"
   //            ><div class="flex flex-row">
   //              <b-button
   //                v-if="casedata.note_subs[field]"
   //                variant="outline-success"
   //                class="mr-1 btn-icon"
   //                @click.stop="
   //                  toggleNotify(
   //                    field,
   //                    'CaseParty',
   //                    field.unique_id,
   //                    casedata.note_subs[field]
   //                  )
   //                "
   //              >
   //                <feather-icon icon="BellIcon" />
   //              </b-button>
// 
   //              <b-button
   //                v-else
   //                variant="outline-warning"
   //                class="mr-1 btn-icon"
   //                @click.stop="
   //                  toggleNotify(
   //                    field,
   //                    'CaseParty',
   //                    field.unique_id,
   //                    casedata.note_subs[field]
   //                  )
   //                "
   //              >
   //                <feather-icon icon="BellOffIcon" />
   //              </b-button>
   //              <b-button
   //                variant="outline-info"
   //                class="mr-1 btn-icon"
   //                @click.stop="
   //                  toggleNotify(
   //                    field,
   //                    'CaseParty',
   //                    field.unique_id,
   //                    casedata.note_subs[field]
   //                  )
   //                "
   //              >
   //                <history-icon />
   //              </b-button>
   //              <div class="float-right underline">{{ title }}</div>
   //            </div>
   //          </b-overlay>
   //        </div>
   //      </div>
   //    </div>
   //    <div v-if="!formInput">
   //      <div class="my-1 d-flex">
   //        <span class="w-full text-center">
   //          {{ casedata[field] ? casedata[field] : "n/a" }}
   //        </span>
   //      </div>
   //    </div>
   //  </div> -->