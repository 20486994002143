<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6">
    <b-card no-body class="card-statistics">
      <data-select
        v-if="dataSelection"
        :data-selection="dataSelection"
        class="mt-1 mx-1"
        v-on="$listeners"
      />
      <!-- divider -->
      <div class="divider mx-2">
        <div class="divider-text">
          <feather-icon size="24" icon="FilterIcon" />
        </div>
      </div>
      <b-card-header>
        <b-card-title>Conversion Rates</b-card-title>
      </b-card-header>

      <b-card-body class="statistics-body">
        <b-row>
          <b-col
            v-for="item in data"
            :key="item.icon"
            xl="6"
            :class="`${item.customClass} mt-1`"
          >
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" :variant="item.color">
                  <feather-icon size="24" :icon="item.icon" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h5
                  v-if="
                    item.subtitle === 'Conversion Rate' ||
                    item.subtitle === 'Top Zip Code'
                  "
                  class="mb-0 font-weight-bolder"
                >
                  {{ item.title }} %
                  <span v-if="item.subtitle === 'Conversion Rate'">%</span>
                </h5>
                <h5 v-else class="mb-0 font-weight-bolder">
                  {{ kFormatter(item.title) }}
                </h5>
                <b-card-text class="mb-0 font-small-3">
                  {{ item.subtitle }}
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </b-overlay>
</template>

<script>
import { kFormatter } from "@core/utils/filter";
import DataSelect from "./DataSelect.vue";
import {
  BOverlay,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BRow,
  BCol,
  BMedia,
  BMediaAside,
  BAvatar,
  BMediaBody,
} from "bootstrap-vue";

export default {
  name: "UserStatistics",
  components: {
    BOverlay,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
    DataSelect,
  },

  props: {
    selectedData: {
      type: Object,
      required: true,
    },
    dataSelection: {
      type: Array,
      required: true,
    },
    isLoaded: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy: true,
      loaded: true,
      data: [
        {
          icon: "Link2Icon",
          color: "light-primary",
          title: "-",
          subtitle: "All Links Mailed",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "LogInIcon",
          color: "light-secondary",
          title: "-",
          subtitle: "Conversion Rate",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "LayersIcon",
          color: "light-primary",
          title: "-",
          subtitle: "Top Stage",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "MapPinIcon",
          color: "light-secondary",
          title: "-",
          subtitle: "Top County",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "MapIcon",
          color: "light-primary",
          title: "-",
          subtitle: "Top Zip Code",
          customClass: "mb-2 mb-xl-0",
        },
        {
          icon: "UserIcon",
          color: "light-secondary",
          title: "-",
          subtitle: "Top User",
          customClass: "mb-2 mb-xl-0",
        },
      ],
    };
  },
  watch: {
    selectedData() {
      this.getData();
    },
  },

  methods: {
    kFormatter,
    getData() {
      this.isBusy = true;
      this.$AuthAxios.instance
        .get("/api-access/filing-view/conversion-rates/", {
          params: this.selectedData,
        })
        .then((res) => {
          this.data = res.data;
          this.isBusy = false;
        })
        .catch(() => {
          this.isBusy = false;
        });
    },
  },
};
</script>
