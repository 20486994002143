<template>
  <b-overlay :show="isBusy" rounded="lg" opacity="0.6" no-body>
    <b-modal
      id="modal-center"
      v-model="showModal"
      scrollable
      header-class="bg-white"
      hide-footer
      size="xl"
    >
      <!-- <p class="my-4">{{ modalValue.item }}</p> -->
      <div v-if="showModal">
        <total-case-view :casedata="caseData"> </total-case-view>
      </div>
    </b-modal>
    <b-card v-if="true" no-body class="card-company-table">
      <div>
        <b-table
          ref="table"
          class="w-full mb-0 min-h-6xl"
          no-local-sorting
          show-empty
          selectable
          border
          select-mode="multi"
          fields="fields"
          responsive
          :items="contactData"
        >
          <template #cell(phone_number)="data">
            {{ fPhone(data.item) }}
          </template>
          <template #cell(case_number)="data">
            <b-button
              v-if="data.item.case_number != 'case ID not provided'"
              variant="gradient-secondary"
              class="shadow-md btn-icon"
              size="sm"
              @click="getCaseData(data.item.case_number)"
            >
              <div class="flex flex-row items-center justify-center">
                <div class="flex flex-row text-xs">View</div>
              </div>
            </b-button>
            <div v-else>{{ data.item.case_number }}</div>
          </template>
        </b-table>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import { BTable, BCard, BOverlay, BButton, BModal } from "bootstrap-vue";
import TotalCaseView from "../total-case-view/TotalCaseView.vue";

export default {
  components: {
    BTable,
    BButton,
    BCard,
    BOverlay,
    BModal,
    TotalCaseView,
  },
  props: {
    contactData: {
      type: Array,
      required: true,
    },
    isLoaded: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      isBusy2: false,
      fieldBy: "case_status",
      filterBy: "none",
      searchFilter: "",
      notificationsDateTo: "",
      notificationsDateFrom: "",
      checkboxSelected: ["notes"],
      // SELECTED VARS
      allChecked: false,
      indeterminateChecked: false,
      checkedViaRow: false,
      selectedRows: [],
      // LOCAL VARS FOR TABLE SORTING & PAGINATION
      pageOptions: [5, 7, 10, 15, { value: 100, text: "Show a lot" }],
      perPage: 10,
      isBusy: false,
      currentPage: 1,
      sortDesc: false,
      sortBy: "viewed",
      totalRows: 5,
      // LOCAL VARIABLES
      caseData: [],
      showModal: false,
      tableData: "",

      fields: [
        {
          key: "created_date",
          label: "Contact Date",
        },
        {
          key: "export_user",
          label: "Exported By:",
        },

        {
          key: "name",
          label: "Client Name",
        },

        {
          key: "email",
          label: "Email",
        },

        {
          key: "phone_number",
          label: "Phone",
        },
        {
          key: "mailing_id",
          label: "Mailing ID",
        },
        {
          key: "pin_number",
          label: "Secret Pin",
        },
        {
          key: "ip_address",
          label: "IP Address",
        },
      ],
    };
  },
  computed: {
    checkSelect() {
      return this.selectedRows.length > 0 ? true : false;
    },
  },
  watch: {
    isLoaded(newValue) {
      if (newValue >= 2) {
        this.isBusy = false;
      }
    },
    // eslint-disable-next-line no-unused-vars
    allChecked(newValue, oldValue) {
      if (newValue) {
        this.$refs.table.selectAllRows();

        return;
      }
      if (!newValue && !this.indeterminateChecked) {
        this.$refs.table.clearSelected();

        return;
      }
    },
  },
  methods: {
    getCaseData(case_number) {
      this.$AuthAxios.instance
        .get("/api-access/search/case-search/", {
          params: {
            single: true,
            caseNumber: case_number,
          },
        })
        .then((res) => {
          this.caseData = res.data.results[0];
          this.showModal = true;
        });
    },
    fPhone(item) {
      // FORMAT PHONE NUMBER
      var x = item.phone_number
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      return !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    viewNotification(id) {
      if (this.$route.name !== "notifications") {
        this.$router.push({
          name: "notifications",
        });
      }
      this.$store.dispatch("notifications/updateSelectedNotification", id);
    },

    rowClass(item, type) {
      if (!item || type !== "row") return "relative";
      if (item._rowVariant === "success") return "relative table-success";
    },
  },
};
</script>
