<!-- THIS COMPONENT CONTROLS THE ACCORDION ACTION FOR EACH OF THE DEFENDANTS
     EXPOSED ON THE PARENT AS A 'v-for' EACH. 
-->
<template>
  <b-card
    no-body
    :class="{ open: visible }"
    @mouseenter="collapseOpen"
    @mouseleave="collapseClose"
  >
    <!-- THE HEADER SLOT -->
    <b-card-header
      :class="{ collapsed: !visible }"
      :aria-expanded="visible ? 'true' : 'false'"
      :aria-controls="collapseItemID"
      role="tab"
      data-toggle="collapse"
      class="border shadow-md"
      @click="updateVisible(!visible)"
    >
      <slot name="header">
        <div class="w-full d-flex justify-content-between">
          <div class="text-xl font-bold text-center w-128">
            <div
              class="
                flex flex-row
                items-center
                justify-center
                w-full
                bg-gray-200
                rounded-lg
              "
              @click.stop
            >
              <b-form-checkbox
                :id="`checkbox-${uid}-${index}`"
                v-model="varDoNotMail"
                :name="`checkbox-${uid}-${index}`"
                :value="true"
                :unchecked-value="false"
                @change="updateDoNotMail()"
              >
                <h4 class="font-extrabold text-danger">DO NOT MAIL</h4>
              </b-form-checkbox>
            </div>

            <div class="flex flex-col w-full mt-1">
              <span class="px-1 mb-1 mr-1 text-white rounded-lg bg-primary"
                >Defendant {{ index + 1 }}:<small class="ml-1">
                  updated {{ updateCalculation(date) }} days ago</small
                ></span
              >
              <span>{{ defendant }}</span>
            </div>
          </div>
          <div class="flex flex-row justify-around h-32 ml-5 mr-1 w-128">
            <mailing-history :obj-item="obj.exports" />
          </div>
          <div
            class="
              flex flex-col
              items-end
              w-1/2
              h-32
              bg-white
              border
              rounded-lg
              shadow-sm
            "
          >
            <div class="w-full pl-1 bg-gray-200">
              <label for="textarea-auto-height">Contact Notepad</label>
            </div>
            <text-input class="w-full" :name="uid" :item="obj" />
          </div>
        </div>
      </slot>
    </b-card-header>
    <!-- MAIN COLLAPSE BODY -->
    <b-collapse
      :id="collapseItemID"
      v-model="visible"
      :accordion="accordion"
      role="tabpanel"
    >
      <b-card-body>
        <slot />
      </b-card-body>
    </b-collapse>
    <div class="w-full h-5 bg-primary"></div>
  </b-card>
</template>

<script>
//import CaseStatusDropdown from "../CaseStatusDropdown"
import {
  BFormCheckbox,
  BCard,
  BCardHeader,
  BCardBody,
  BCollapse,
} from "bootstrap-vue";
import { v4 as uuidv4 } from "uuid";
import TextInput from "../TextInput.vue";
import MailingHistory from "./MailingHistory.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "AppCollapseItem",
  components: {
    TextInput,
    MailingHistory,
    //CaseStatusDropdown,
    BCard,
    BCardHeader,
    BCardBody,
    BCollapse,
    BFormCheckbox,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
    obj: {
      type: Object,
      required: true,
    },
    totalid: {
      type: Number,
      default: 0,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    index: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    defendant: {
      type: String,
      required: true,
    },
    doNotMail: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      varDoNotMail: this.doNotMail,
      visible: false,
      collapseItemID: "",
      openOnHover: this.$parent.hover,
    };
  },
  computed: {
    accordion() {
      return this.$parent.accordion
        ? `accordion-${this.$parent.collapseID}`
        : null;
    },
  },
  created() {
    this.collapseItemID = uuidv4();
    this.visible = this.isVisible;
  },
  methods: {
    updateDoNotMail() {
      this.$AuthAxios.instance
        .post("/api-access/search/update-do-not-mail/", {
          params: {
            uid: this.obj.unique_id,
            mail_value: this.varDoNotMail,
          },
        })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `${this.defendant} has been successfully ${res.data} the DO NOT MAIL LIST!`,
              icon: "MailIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `OOPS, Something went wrong..... ${error}`,
              icon: "CircleXIcon",
              variant: "danger",
            },
          });
        });
    },
    updateCalculation(d1) {
      var t1 = new Date(d1).getTime();
      var t2 = new Date().getTime();

      return parseInt((t2 - t1) / (24 * 3600 * 1000));
    },
    updateVisible(val = true) {
      this.visible = val;
      this.$emit("visible", val);
    },
    collapseOpen() {
      if (this.openOnHover) this.updateVisible(true);
    },
    collapseClose() {
      if (this.openOnHover) this.updateVisible(false);
    },
  },
};
</script>
