<!-- THIS COMPONENT CONTROLS THE ACCORDION ACTION FOR EACH OF THE DEFENDANTS
     EXPOSED ON THE PARENT AS A 'v-for' EACH. 
-->
<template>
  <div role="tablist" class="collapse-icon" :class="collapseClasses">
    <slot />
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid"

export default {
  props: {
    type: {
      type: String,
      default: "default"
    }
  },
  data() {
    return {
      collapseID: ""
    }
  },
  computed: {
    // THIS FUNCTION SETS THE COLLAPSE VARIANT. CURRENTLY DEFAULT.
    collapseClasses() {
      const classes = []
      // Collapse Type
      const collapseVariants = {
        default: "collapse-default",
        shadow: "collapse-shadow",
        margin: "collapse-margin"
      }
      classes.push(collapseVariants[this.type])
      return classes
    }
  },
  created() {
    /* CREATING THE UNIQUE 'collapseID'. COULD USE CaseParty.unique_id IN THE
       FUTURE.
    */
    this.collapseID = uuidv4()
  }
}
</script>
