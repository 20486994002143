<template>
  <div>
    <b-dropdown
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      :text="selected"
      right
      size="sm"
      variant="flat-secondary"
    >
      <b-dropdown-item
        v-for="item in timeFrame"
        :key="item"
        @click="setSelect(item)"
      >
        {{ item }}
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown, BDropdownItem } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: { BDropdown, BDropdownItem },
  directives: {
    Ripple,
  },
  data() {
    return {
      selected: "Last 14 days",
      timeFrame: [
        "Last 7 days",
        "Last 14 days",
        "Last 30 days",
        "Last 3 months",
        "Last 6 months",
        "Last 12 months",
        "This year",
        "Last year",
      ],
    };
  },
  methods: {
    setSelect(item){
      this.selected = item
      this.$emit('dateSelected', item)
      

    }
  }
};
</script>
 