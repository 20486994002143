<template>
  <b-tr v-if="active">
    <b-td>
      <div class="font-bold">
        <b-overlay
          :show="overlay"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="flex flex-row"
        >
          <b-button
            v-if="casedata.note_subs[field]"
            v-b-tooltip.hover.v-primary
            title="toggle notification for this field"
            :delay="delay"
            variant="outline-success"
            class="mr-1 btn-icon"
            @click.stop="
              toggleNotify(field, model, pk, casedata.note_subs[field])
            "
          >
            <feather-icon icon="BellIcon" />
          </b-button>

          <b-button
            v-else
            v-b-tooltip.hover.v-primary
            title="toggle notification for this field"
            :delay="delay"
            variant="outline-warning"
            class="mr-1 btn-icon"
            @click.stop="
              toggleNotify(field, model, pk, casedata.note_subs[field])
            "
          >
            <feather-icon icon="BellOffIcon" />
          </b-button>

          <b-button
            v-b-tooltip.hover.v-primary
            title="show history for this field"
            :delay="delay"
            variant="outline-info"
            class="mr-1 btn-icon"
            @click.stop="
              $getComponent('TotalCaseView').toggleHistoryAccordion({
                active: true,
                field: field,
                model: 'Case',
                pk: casedata.case_number,
              })
            "
          >
            <history-icon />
          </b-button>
          <div>{{ title }}:</div>
        </b-overlay>
      </div>
    </b-td>

    <b-td
      v-if="active"
      class="d-flex justify-content-between align-items-center"
    >
      <span>
        {{
          casedata[field] === false
            ? "No"
            : casedata[field] === true
            ? "Yes"
            : casedata[field]
        }}
      </span>
    </b-td>
  </b-tr>

  <b-tr v-else>
    <b-td>
      <div class="flex flex-row font-bold">{{ title }}:</div>
    </b-td>

    <b-td class="d-flex justify-content-between align-items-center">
      <span v-if="field === 'amount'">
        {{ currencyFormat(casedata[field]) }}
      </span>

      <span v-else>
        {{
          casedata[field] === false
            ? "No"
            : casedata[field] === true
            ? "Yes"
            : casedata[field]
        }}
      </span>
    </b-td>
  </b-tr>
</template>

<script>
import { VBTooltip, BButton, BTd, BTr, BOverlay } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import HistoryIcon from "./HistoryIcon";

export default {
  name: "TrackedToggle",
  components: {
    HistoryIcon,
    BButton,
    BTd,
    BTr,
    BOverlay,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  props: {
    casedata: {
      type: Object,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    model: {
      type: String,
      required: true,
    },
    pk: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      overlay: false,
      delay: { show: 500, hide: 500 },
    };
  },

  methods: {
    currencyFormat(v) {
      return `$${("" + v).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    },
    async toggleNotify(field, model, pk, toggleValue) {
      const l1 = this;
      l1.overlay = true;
      if (toggleValue) {
        await this.$AuthAxios.instance
          .delete("/api-access/notifications/add-remove-notifications/", {
            params: {
              field: field,
              model: model,
              pk: pk,
            },
          }) // eslint-disable-next-line no-unused-vars
          .then((res) => {
            l1.overlay = false;

            this.$toast({
              component: ToastificationContent,
              props: {
                title: `The '${field}' notification has been removed!`,
                icon: "EyeOffIcon",
                variant: "warning",
              },
            });

            l1.casedata.note_subs[field] = false;

            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index,
            });
          })
          .catch((error) => {
            l1.overlay = false;
            l1.casedata.note_subs[field] = true;

            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index,
            });
            console.log(error);
          });
      } else {
        await this.$AuthAxios.instance
          .post("/api-access/notifications/add-remove-notifications/", null, {
            params: {
              field: field,
              model: model,
              pk: pk,
            },
          }) // eslint-disable-next-line no-unused-vars
          .then((res) => {
            if (!l1.casedata.is_tracked) {
              l1.$emit("toggleTracker", true);
            }

            l1.overlay = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `The '${field}' notification has been successfully set!`,
                icon: "EyeOnIcon",
                variant: "success",
              },
            });

            l1.casedata.note_subs[field] = true;
            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index,
            });
          })
          .catch((error) => {
            l1.overlay = false;
            l1.casedata.note_subs[field] = false;
            this.$emit("updateTotalCaseView", {
              subs: l1.casedata.note_subs,
              index: l1.casedata.index,
            });
            console.log(error);
          });
      }
    },
  },
};
</script>