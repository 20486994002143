<template>
  <div :class="`${componentPadding}`">
    <b-collapse :id="`collapse-${dataPack.pk}`">
      <b-card :class="`border-none ${bgColor} shadow-none`">
        <b-card-text>
          <hr />
          <div class="flex flex-col">
            <div class="flex flex-row justify-start mt-2">
              <div class="font-bold text-gray-700 text-md">
                {{ formatStatus(history) }}
              </div>
            </div>
            <div class="flex flex-row justify-end">
              <div>
                <b-button
                  variant="secondary"
                  class="mb-2 mr-1"
                  @click.stop="resetHistory"
                >
                  Close
                </b-button>
              </div>
            </div>
            <div class="self-center w-full">
              <history-table :table-data="history[Object.keys(history)[0]]" />
            </div>
          </div>
        </b-card-text>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import { BButton, BCollapse, BCard, BCardText } from "bootstrap-vue"
import HistoryTable from "./HistoryTable.vue"
export default {
  name: "TrackedToggle",

  components: {
    BButton,
    BCard,
    BCollapse,
    HistoryTable,
    BCardText
  },

  props: {
    dataPack: {
      type: Object,
      required: true
    },
    bgColor: {
      type: String,
      default: "bg-gray-200"
    },
    componentPadding: {
      type: String,
      default: "px-1"
    }
  },

  data() {
    return {
      history: false,
      field: "",
      model: "",
      pk: ""
    }
  },
  watch: {
    // whenever question changes, this function will run
    dataPack: function(nv) {
      this.field = nv.field
      this.model = nv.model
      this.pk = nv.pk

      this.getHistory()
    }
  },

  methods: {
    async getHistory() {
      const field = this.field
      const model = this.model
      const pk = this.pk
      //var l1 = this
      await this.$AuthAxios.instance
        .get("/api-access/notifications/case-history/history/", {
          params: {
            field: field,
            model: model,
            pk: pk
          }
        }) // eslint-disable-next-line no-unused-vars
        .then((res) => {
          this.showHistory(res.data)
        })
        .catch((error) => {
          console.log(error)
        })
    },
    showHistory(data) {
      if (!this.history) {
        this.history = data

        return this.$root.$emit(
          "bv::toggle::collapse",
          `collapse-${this.dataPack.pk}`
        )
      }

      this.history = data
    },
    resetHistory() {
      this.history = false
      this.$root.$emit("bv::toggle::collapse", `collapse-${this.dataPack.pk}`)
    },
    formatStatus(status) {
      var statusValue = ""
      var capStatus = "History for"
      if (typeof status === "object") {
        statusValue = Object.keys(status)[0]
        const splitData = statusValue.split("_")

        splitData.forEach((word) => {
          var splitWord = word.charAt(0).toUpperCase() + word.slice(1)

          capStatus = `${capStatus} ${splitWord}`
        })

        return capStatus
      }
    }
  }
}
</script>


