<template>
  <div>
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        class="feather feather-history"
      >
        <polyline points="12 6 12 12 16 14"></polyline>
        <path
          d="M2 13.24a9.67 9.67 0 0 0 2.71 5.83 10.2 10.2 0 0 0 14.32 0 9.89 9.89 0 0 0 0-14.14 10.2 10.2 0 0 0-13.52-.7C5.24 4.44 2.26 7.74 2 8"
        ></path>

        <path d="M6 9H1V4"></path>
      </svg>
    </div>
  </div>
</template>

<script>
export default {}
</script>
