
<template>
  <div class="w-full">
    <!-- TODO: CONVERT COMPONENT INTO A CONTACT HISTORY / NOTE INTERFACE.
                OPTIONS SHOULD INCLUDE >
                  * NOTE
                  * WALK-IN
                  * APPOINTMENT
                  * PHONE CALL
                  * EMAIL
                  * OTHER CONTACT
                
     -->
    <div class="relative">
      <b-form-textarea
        v-model="text"
        debounce="500"
        class="pr-2"
        rows="3"
        max-rows="3"
        :placeholder="
          `${name} is interested in filing a bankruptcy. Will follow up ....  `
        "
        @click.stop.prevent
        @input="
          initial = false
          success = false
        "
      />
      <div v-if="!initial">
        <div v-if="success" class="absolute top-1 right-1">
          <feather-icon class="text-success" icon="CheckCircleIcon" />
        </div>
        <div v-else class="absolute top-0 right-1">
          <b-spinner variant="primary" small></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormTextarea, BSpinner } from "bootstrap-vue"

export default {
  components: {
    BFormTextarea,
    BSpinner
  },
  props: {
    name: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      success: false,
      initial: true,
      text: this.getText(),
      exists: this.getExists(),
      showModal: false
    }
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    text(newValue) {
      this.pushEndpoint()
    }
  },

  methods: {
    getExists() {
      if (this.item.notepad.exists) {
        return true
      }

      return false
    },
    getText() {
      return this.item.notepad.note
    },
    pushEndpoint() {
      var l1 = this

      let callAxios = (axios) => {
        axios("/api-access/user-data/caseparty-notepad/notepad/", {
          note_text: l1.text,
          unique_id: l1.item.unique_id
        }) // eslint-disable-next-line no-unused-vars
          .then(function(res) {
            l1.success = true
          })
          .catch((error) => {
            console.log(error)
          })
      }

      if (l1.exists) {
        callAxios(l1.$AuthAxios.instance.put)
      } else {
        callAxios(l1.$AuthAxios.instance.post)
        l1.exists = true
      }
    }
  }
}
</script>